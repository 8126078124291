import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarComponent from '../components/navbar';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useNavigate();
    const [error, setError] = useState(null);
    const [isNavbarVisible, setNavbarVisibility] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/login`, { username, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('username', username);
            history.push('/dashboard');
            setError(null);
            setUsername('');
            setPassword('');
        } catch (error) {
            console.error('Error during login:', error);
            setError("Details are incorrect or there was an issue fetching the data.");
        }
    };

    return (
        <div className=' flex flex-col items-center justify-center h-screen bg-[#142335]'>
            <NavbarComponent isNavbarVisible={isNavbarVisible} setNavbarVisibility={setNavbarVisibility} />
            <h1 className='text-white font-bold md:text-3xl text-lg'>Login</h1>
            <div className=' mt-5 flex flex-col items-center justify-center bg-[#E4E4E4] md:w-[824px] md:h-[446px]  w-4/5 h-2/5 md:w-3/5 md:h-3/5 rounded-2xl'>
                <form onSubmit={handleSubmit} className=' flex flex-col justify-center   md:items-center mx-auto mr-16 sm:mr-auto
                 gap-5 md:w-[824px] md:h-[446px] h-4/5 w-3/5 font-orbitron'>
                    <input
                        className=" md:w-[350px] lg:w-[470px] md:h-[77px] w-[180px] h-[50px] sm:w-[300px] sm:h-[50px]  pl-4"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        className="  md:w-[350px] lg:w-[470px] md:h-[77px] w-[180px] h-[50px] sm:w-[300px] sm:h-[50px]  pl-4 "
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className=" bg-[#ECEFF2] text-[#262626] md:w-[300px] lg:w-[352px] w-[180px] h-[50px] md:h-[70px] sm:w-[300px] sm:h-[50px] text-center  md:ml-5 lg:ml-8  border-none font-orbitron text-lg sm::text-xl" type="submit">Login</button>
                </form>
                {error && <div className=" text-red-500 font-bold mt-4">{error}</div>}
            </div>
        </div>
    );
};

export default Login;
