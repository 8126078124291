import React from "react";
import WindBooster1 from "../Images/WindBooster1.png";
import { useNavigate } from "react-router-dom";

function Products() {
  const navigate = useNavigate();
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section
      id="products"
      className="h-[100vh] w-[100vw] text-center bg-[#142335] box-border snap-start"
    >
      <div className="flex w-[100vw] justify-center items-center h-[100%] flex-col">
        <span className="flex w-[100vw] justify-center gap-16 md:gap-0 flex-col sm:flex-row items-center h-[100%]">
          <div
            data-aos="fade-left"
            className="
       
       flex flex-col w-[50%]  items-center  element"
            onClick={() =>
            scrollToSection("wind-booster")
           
            }
          >
            <h1 className="text-white    text-center  font-semibold md:text-[64px] ">
              GOT <br /> WindBooster
            </h1>

            <img
              src={WindBooster1}
              alt=""
              class="md:ml-0   mt-8 cursor-pointer md:w-[326px] md:h-[208px] sm:w-[160px]  sm:h-[100px]   w-[160px] relative z-10 color shadow-custom"
              onClick={() =>
                scrollToSection("wind-booster")
              }
            />
          </div>
          <div
            data-aos="fade-right"
            className="
       
       flex flex-col w-[50%] mt-10 sm:mt-0  items-center  element"
            onClick={() =>
              window.location.pathname !== "/checkwarranty" &&
              window.location.pathname !== "/login"
                ? scrollToSection("got-boost")
                : navigate("/got-boost")
            }
          >
            <h1 className="text-white    text-center  font-semibold md:text-[64px] ">
              GOT <br /> Booster
            </h1>

            <img
              src={WindBooster1}
              alt=""
              class="md:ml-0   mt-8 cursor-pointer md:w-[326px] md:h-[208px] sm:w-[160px]  sm:h-[100px]   w-[160px] relative z-10 color shadow-custom"
              onClick={
                window.location.pathname !== "/checkwarranty" &&
                window.location.pathname !== "/login"
                  ? scrollToSection("got-boost")
                  : navigate("/got-boost")
              }
            />
          </div>
        </span>
        <div className="mb-8">
          {" "}
          <h1 className=" md:text-5xl font-bold mx-auto text-xl text-center text-white">
            The Ultimate Throttle Controller
          </h1>
          <h2 className=" md:text-3xl text-md mt-4 font-normal pt-1 text-center text-white">
            Give your car the <b>BOOST</b> it wants
          </h2>{" "}
        </div>
      </div>
    </section>
  );
}

export default Products;
