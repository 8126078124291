import React from 'react'

function AboutUs() {
  return (
    <section id="about-us" className="  text-center bg-[#142335] m-0  pt-[120px]    snap-start   ">
    <div className=" flex flex-col justify-start items-center   gap-4">
      <h1 className=" md:text-5xl font-bold mx-auto text-xl text-center text-white">
        About Us
      </h1>
      <h2 className=" md:text-3xl text-2xl font-normal pt-1 text-center text-white">
        Get to know more about GOT Boost
      </h2>

      <div
        data-aos="zoom-out-down "
        className="pl-10  py-8  space-y-5 md:pl-8 lg:pl-20 pt-8 w-[90%]  flex flex-col text-white "
      >
        <div className="space-y-1 text-start">
          <h3 className="md:text-3xl text-xl font-[900]">
            About GOT Boost Throttle Controller
          </h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            Welcome to GOT Boost, your gateway to enhanced driving
            experiences. Our throttle controller is designed to redefine
            the way you interact with your vehicle's engine, putting you
            in control of the road like never before.
          </p>
        </div>
        <div className="space-y-1 text-start">
          <h3 className="md:text-3xl text-xl font-[900]">What is GOT Boost?</h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            GOT Boost is a cutting-edge throttle enhancement system
            developed by the dynamic father-son duo behind Gang of Thars,
            the world's largest 4x4 community. With years of experience in
            research and development, this team of automotive enthusiasts
            has created a product that sets a new standard for driving
            enjoyment.
          </p>
        </div>
        <div className=" space-y-1 text-start">
          <h3 className="md:text-3xl text-xl font-[900]">How Does It Work?</h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            Our state-of-the-art throttle controller alters the
            communication between your vehicle's accelerator pedal and the
            engine, giving you precise control over acceleration. With 12
            advanced driving modes, GOT Boost ensures that your car
            responds to your driving style and environment. Whether you're
            looking for maximum performance on the track or a smooth,
            fuel-efficient drive in the city, GOT Boost has you covered.
          </p>
        </div>
        <div className=" space-y-1 text-start">
          <h3 className=" md:text-3xl text-xl font-[900]">
            Our Founders: The Driving Force
          </h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            At the heart of GOT Boost is the visionary father-son duo
            behind Gang of Thars. With years of experience in research and
            development, they embarked on a mission to transform the
            driving experience for enthusiasts worldwide. Their dedication
            to engineering excellence and a commitment to innovation led
            to the development of GOT Boost, a product that reflects their
            core values of precision, performance, and personalization.
          </p>
        </div>
        <div className=" space-y-1 text-start">
          <h3 className="md:text-3xl text-xl font-[900]">
            Intensive Testing for Excellence
          </h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            GOT Boost was intensively tested for hundreds of hours on and
            off the road to ensure you get the best throttle response.
            This commitment to perfection has made GOT Boost a trusted
            name in the automotive community.
          </p>
        </div>
        <div className="  space-y-1 text-start">
          <h3 className=" md:text-3xl text-xl font-[900]">
            Join Us on the Road Ahead
          </h3>
          <p className="text-xl md:text-2xl font-['Inter_Tight'] font-[500]">
            As we continue to evolve and innovate, we invite you to join
            us on this thrilling journey. Explore the possibilities of
            optimized throttle control with GOT Boost, and experience the
            road like never before.
          </p>
          <p className='h-[30px]'></p>
          <p className="text-xl md:text-3xl mb-4">
            Discover the power to enhance your driving adventure today
            with GOT Boost Throttle Controller.
          </p>
          <p className='h-[300px]'><p></p></p>
        </div>
      </div>
    </div>
  </section>  )
}

export default AboutUs