import "./checkwarranty.css"
import NavbarComponent from '../components/navbar';
import React, { useState } from 'react';
import axios from 'axios';


const CheckWarranty = () => {
    const [serialNumber, setSerialNumber] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [productDetails, setProductDetails] = useState(null);
    const [isFlipped, setFlipped] = useState(false);
    const [error, setError] = useState(null);
    const [isNavbarVisible, setNavbarVisibility] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:3001/api/products/checkWarranty', { serialNumber, phoneNo });
            setProductDetails(response.data);
            setFlipped(true);
            setError(null);
        } catch (error) {
            console.error("Error fetching product details:", error);
            setError("Details are incorrect or there was an issue fetching the data.");
        }
    };
    return (

        <div className=" flex flex-col items-center justify-center h-screen overflow-hidden bg-[#142335]">
            <NavbarComponent enableScrollingBehavior='false' isNavbarVisible={isNavbarVisible} setNavbarVisibility={setNavbarVisibility} />
            <h1 className=" text-white font-bold md:text-3xl text-lg">Check Your Warranty Status</h1>
            <div className="mt-5 flex flex-col items-center justify-center bg-[#E4E4E4] md:w-[824px] md:h-[446px] w-4/5 h-2/5 md:w-3/5 md:h-3/5 rounded-3xl" >
                <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
                    <div className=" flex flex-col justify-center  h-[100%]  items-center rounded-[2rem] gap-[1vh] bg-transparent ">
                        {!isFlipped && (<form className="flex flex-col md:space-y-3 lg:items-center items-center md:items-center mx-auto mr-16 sm:mr-auto
                 gap-5 md:w-[824px]  w-3/5 h-3/5 font-orbitron" onSubmit={handleSubmit} action="#" method="post">
                            <input value={serialNumber}
                                onChange={(e) => setSerialNumber(e.target.value)}
                                type="text"
                                className=" md:w-[350px] lg:w-[470px] md:h-[77px] w-[180px] h-[50px] sm:w-[300px] sm:h-[50px]  pl-4 "
                                placeholder="Serial No.*"
                                required />
                            <input value={phoneNo}
                                onChange={(e) => setPhoneNo(e.target.value)}
                                type="text"
                                className=" md:w-[350px] lg:w-[470px] md:h-[77px] w-[180px] h-[50px] sm:w-[300px] sm:h-[50px]  pl-4 "

                                placeholder="Phone No.*"
                                required />
                            <button type="submit" className="bg-[#ECEFF2] text-[#262626] md:w-[300px] lg:w-[352px] w-[180px] h-[50px] md:h-[70px] sm:w-[300px] sm:h-[50px] text-center  md:ml-5 lg:ml-8  border-none font-orbitron text-lg sm::text-xl">Check Warranty</button>
                            {error && <div className="text-red-500 font-bold m4-4">{error}</div>}
                        </form>
                        )}

                    </div >
                    <div className="card-back">
                        <div className="gradwrap">
                            <div className="grad"></div>
                            {productDetails && (
                                <div className="proddet">
                                    <h1>Warranty Card</h1>
                                    <p>Serial No.: {productDetails.serialNumber}</p>
                                    <p>Customer Name: {productDetails.customerName}</p>
                                    <p>Vehicle Registration Number: {productDetails.vehicleRegNo}</p>
                                    <p>Installation Date: {productDetails.installationDate}</p>
                                    <p>Production Date: {productDetails.productionDate}</p>
                                    <p>Time Left in Warranty (months): {productDetails.timeLeftInWarranty.toFixed(1)}</p>
                                    <p>Registered by: {productDetails.username}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CheckWarranty;