import React from 'react';
import WindBooster1 from "../Images/WindBooster1.png";
import OrderonAmazon from "../Images/OrderonAmazon.png";

function GotBoost() {
  return (
    <section id='got-boost' className="pt-8 sm:pt-0  h-[100vh] w-[100vw] text-center bg-[#142335] box-border snap-start">
    <h1 className="text-white m-auto  pt-[6.7rem]    md:text-[35px]  lg:text-[50px] xl:text-[90px] md:leading-[112px]  text-2xl">
      What is <b>GOT Boost</b>?
    </h1>
    <div className="flex lg:pl-24 pl-5 gap-20 lg:gap-8 xl:gap-28 flex-col sm:flex-row items-center justify-center">
      <div className="flex flex-col gap-20 lg:gap-16 xl:gap-28 xl:mt-10  mt-4 sm:mt-0   items-center">
        <img
          data-aos="zoom-in-left"
          src={WindBooster1}
          className=" color shadow-custom1 md:w-[259px] md:h-[165px] w-[160px]"
          alt=""
        />{" "}
        <a
          href="https://amazon.in"
          className="flex items-center justify-center lg:block pt-0 md:pt-[12rem] lg:pt-0"
        >
          <img
            src={OrderonAmazon}
            className=" mt-2 md:w-[241px] md:h-[76px] w-32"
            alt=""
          />
        </a>
      </div>
      <h1 className="text-white lg:text-[30px] xl:text-[40px] md:leading-[48px] lg:ml-20 text-md sm:ml-11 md:ml-10  text-left w-[250px] sm:w-[500px] md:w-[640px] md:h-[294px]">
        <b>GOT Boost</b> is an advanced <b>throttle enhancement system</b>{" "}
        that alters the throttle response, it includes{" "}
        <b>12 advanced driving modes</b> including an{" "}
        <b>advanced AI Pro mode</b> that learns from your driving style.
        <h1 className="underline mt-4">
          <b>Know More...</b>
        </h1>
      </h1>
    </div>
  </section>  )
}

export default GotBoost