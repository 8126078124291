import React from 'react';
import mobile from "../Images/mob.png";


function Mobileappcontrol() {
  return (
    <section
    className="pt-20  md:pt-0 h-[100vh] w-[100vw] text-center bg-[#142335] box-border snap-start"
    id="mobile-app-control"
  >
    <div className="flex flex-col justify-center lg:justify-end items-center h-[100vh]">
      <h1
        data-aos="fade-down"
        className="text-white text-xl md:text-[84px] font-normal text-center leading-[104px]"
      >
        Mobile App Control
      </h1>
      <div className="flex flex-col   ">
        <img
          src={mobile}
          className="max-w-[90vw] m-auto max-h-[60vh] sm:w-[40vw] sm:h-[40vh] md:w-auto md:h-auto my-10 block"
          alt=""
        />
        <h1 className="mx-4 mb-4  text-white md:text-4xl text-lg font-light">
          Customize Your <b>Car’s Power</b> from Your <b>Phone</b>
        </h1>
      </div>
    </div>
  </section>  )
}

export default Mobileappcontrol