import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import GotBoostLogo from '../Images/gotBoostLogo.svg'

const NavbarComponent = ({
  isNavbarVisible,
  setNavbarVisibility,
  enableScrollingBehavior,
}) => {
  //   const [expanded, setExpanded] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [pathname, setPathname] = useState("/introduction");
const navigate = useNavigate();
  // const [isNavbarVisible, setNavbarVisibility] = useState(false);

  //   const toggleExpand = (id) => {
  //     if (expanded === id) setExpanded(null);
  //     else setExpanded(id);
  //   };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
    setNavbarVisibility(false);
  };

  const toggleNavbar = () => {
    setNavbarVisibility(!isNavbarVisible);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/";
  };
  const [showLogo, setShowLogo] = useState(false);
  //   if(window.location.pathname === "/"){
  //     setShowLogo(false);
  //   }
useEffect(()=>{
  setPathname(window.location.pathname);

},[])
  useEffect(() => {
    const handlePathnameChange = () => {
      setPathname(window.location.pathname);
    };
    window.addEventListener("scroll", handlePathnameChange);
    return () => {
      window.removeEventListener("scroll", handlePathnameChange);
    };
  }, []);
  useEffect(() => {
    if (window.location.pathname !== "/introduction" || window.location.pathname !== "/") {
      setShowLogo(true);
    }
    if (enableScrollingBehavior) {
      const handleScroll = () => {
        if (window.scrollY > 700) {
          setShowLogo(false);
        } else {
          setShowLogo(true);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [enableScrollingBehavior]);
  console.log('pathname',pathname,)
  return (
    <div
    className={`${!(pathname === '/introduction' || pathname === "/") ? "navbar-container" : ""} fixed z-[102] w-full h-[90px]`}
    >
      {/* <div className='border border-red fixed z-[102] w-[200px] h-[100px]'> */}
      <button
        onClick={toggleNavbar}
        className="z-[101] cursor-pointer text-5xl bg-transparent w-[42px] h-[32px] fixed top-[0.5em] left-5 md:left-[1em] text-white"
      >
        {isNavbarVisible ? (
          <i className="fa fa-times"></i>
        ) : (
          <i className="fa fa-bars"></i>
        )}
      </button>

      {!isNavbarVisible && !showLogo && (
        <div
          className="logo"
          onClick={() => {
            navigate("/introduction")
            
          }}
        >
          <img src={GotBoostLogo} />
        </div>
      )}
      {isNavbarVisible && (
        <div className="overlay-navbar ">
          <button
            className="home-btn"
            onClick={() => {
             (window.location.pathname !== '/checkwarranty' && window.location.pathname !== '/login') ?  scrollToSection("introduction") : navigate('/introduction')
                
            }}
          >
            GOT Boost
          </button>
          <button
            className="nav-btn"
            onClick={() => {
            (window.location.pathname !== '/checkwarranty' && window.location.pathname !== '/login') ?  scrollToSection("products") : navigate('/products')

            }}
          >
            Products
          </button>
          <button
            className="nav-btn"
            onClick={() => {
           (window.location.pathname !== '/checkwarranty' && window.location.pathname !== '/login')  ?  scrollToSection("mobile-app-control") : navigate('/mobile-app-control')

            }}
          >
            Mobile App
          </button>
          <button
            className="nav-btn"
            onClick={() => {
               (window.location.pathname !== '/checkwarranty' && window.location.pathname !== '/login') ?  scrollToSection("about-us") : navigate('/about-us')

            
            }}
          >
            About Us
          </button>
          <button
            className="nav-btn"
            onClick={() => {
                 (window.location.pathname !== '/checkwarranty' && window.location.pathname !== '/login')?  scrollToSection("contact-us") : navigate('/contact-us')

            }}
          >
            Contact Us
          </button>
          <a href="/checkwarranty">
            <button className="nav-btn">Check Warranty</button>
          </a>
          <a
            href={isAuthenticated ? "/register" : "/login"}
            className="login-register-btn"
          >
            {isAuthenticated ? "Register Product" : "Login"}
          </a>
          {isAuthenticated ? (
            <button onClick={logout} className="logout-btn">
              Logout
            </button>
          ) : null}
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default NavbarComponent;
