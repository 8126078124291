import React, { useState } from 'react';
import axios from 'axios';
import './register.css';
import NavbarComponent from '../components/navbar';

const Register = () => {
  const [serialNumber, setSerialNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [vehicleRegNo, setVehicleRegNo] = useState('');
  const [installationDate] = useState(new Date().toISOString().slice(0, 10)); // Default to today's date
  const loggedInUsername = localStorage.getItem('username');
  const [isNavbarVisible, setNavbarVisibility] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productDetails = {
      serialNumber,
      customerName,
      phoneNo,
      vehicleRegNo,
      installationDate,
      username: loggedInUsername
    };

    try {
      // Assuming you have an endpoint to handle the product registration
      await axios.post('http://localhost:3001/api/products/register', productDetails);
      alert('Product details registered successfully');
    } catch (error) {
      console.error('Error during product registration:', error);
    }
  };

  return (
    <div className='register'>
      <NavbarComponent isNavbarVisible={isNavbarVisible} setNavbarVisibility={setNavbarVisibility} />
      <h1>Register Product</h1>
      <div className='register__info'>
        <form onSubmit={handleSubmit} className='register__form'>
          <div>
            <label className='lbl'>Serial Number:</label>
            <input className="inp" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} required />
          </div>
          <div>
            <label className='lbl'>Customer Name:</label>
            <input className="inp" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
          </div>
          <div>
            <label className='lbl'>Phone No:</label>
            <input className="inp" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} required />
          </div>
          <div>
            <label className='lbl'>Vehicle Reg. No:</label>
            <input className="inp" value={vehicleRegNo} onChange={(e) => setVehicleRegNo(e.target.value)} required />
          </div>
          <div>
            <label className='lbl'>Installation Date:</label>
            <input className="inp" type="date" value={installationDate} readOnly />
          </div>
          <button className="checkbutton" type="submit">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
