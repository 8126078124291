import React from 'react'

function ContactUs() {
  return (
    <section
    className="h-[100vh] w-[100vw] text-center bg-[#142335]   snap-start "
    id="contact-us"
  >
    <div
      data-aos="zoom-in"
      className="  flex flex-col justify-start items-center h-[100%] space-y-4"
    >
      <h1 className=" md:pt-40 pt-52 sm:pt-24 font-bold text-white text-5xl">
        Contact Us
      </h1>
      <h2 className=" text-white md:text-[32px] mt-0 font-light">
        We are here to answer all your queries
      </h2>
      <div className=" flex flex-col space-y-2 items-center text-white md:pt-40 md:w-[70vw]">
        <div className="  flex md:flex-row md:gap-4   space-x-5 md:space-x-0">
          <h3 className=" md:text-4xl text-lg   font-bold">
            Helpline No.:{" "}
          </h3>
          <p className=" md:text-4xl text-lg font-extrabold  underline font-['Inter_Tight']">
            +91 8069 06 7299
          </p>
        </div>
        <div className=" flex md:flex-row gap-4">
          <h3 className=" text-lg md:text-4xl font-bold">Email: </h3>
          <p className="  text-lg md:text-4xl font-extrabold underline">
            <a
              className=" text-white text-lg md:text-4xl font-['Inter_Tight']"
              href="mailto:info@boostgot.com"
            >
              info@boostgot.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>  )
}

export default ContactUs