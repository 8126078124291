import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CheckWarranty from './pages/checkwarranty';
import Home from './pages/home';
import Login from './pages/login';
import Register from "./pages/register";
import ProtectedRoute from './pages/protectedroute';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={
          <ProtectedRoute>
            <Register />
          </ProtectedRoute>
        } />
                <Route  path="/introduction" element={<Home />} />
                <Route  path="/got-boost" element={<Home />} />
                <Route  path="/wind-booster" element={<Home />} />
                <Route  path="/contact-us" element={<Home />} />
                <Route  path="/about-us" element={<Home />} />
                <Route  path="/mobile-app-control" element={<Home />} />
                <Route  path="/products" element={<Home />} />
                <Route path="*" element={<Navigate to="/" replace />} />



        <Route index element={<Home />} />

        <Route path="/checkwarranty" element={<CheckWarranty />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;