// ParticleCanvas.js

import React, { useEffect, useRef } from 'react';

const ParticleCanvas = ({ width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    const particleArray = [];
    const numberOfParticles = 100;

    class Particle {
      constructor(x, y, size, color, maxAlpha) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.color = color;
        this.maxAlpha = maxAlpha;
        this.alpha = 0;
        this.alphaChange = 0.005;
        this.dx = (Math.random() * 0.5) - 0.1; // Velocity in x direction
        this.dy = (Math.random() * 0.5) - 0.1; // Velocity in y direction
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);

        // Add glow effect
        ctx.shadowBlur = 15;
        ctx.shadowColor = 'white';

        ctx.fillStyle = `rgba(255, 255, 255, ${this.alpha})`;
        ctx.fill();

        // Remove glow effect for other elements
        ctx.shadowBlur = 0;

        ctx.closePath();
      }

      update() {
        if (this.alpha < 0 || this.alpha > this.maxAlpha) {
          this.alphaChange = -this.alphaChange;
        }

        this.alpha += this.alphaChange;
        this.x += this.dx;  // Update x position
        this.y += this.dy;  // Update y position
        this.draw();
      }
    }

    for (let i = 0; i < numberOfParticles; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 5 + 1; // Smaller size
      const color = 'rgba(255, 255, 255, 1)';
      const maxAlpha = Math.random() * 0.5 + 0.2;
      particleArray.push(new Particle(x, y, size, color, maxAlpha));
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particleArray.length; i++) {
        particleArray[i].update();
      }

      requestAnimationFrame(animate);
    }

    animate();
  }, [width, height]);

  return <canvas ref={canvasRef}></canvas>;
};

export default ParticleCanvas;
