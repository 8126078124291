import React from 'react';
import WindBooster1 from "../Images/WindBooster1.png";
import OrderonAmazon from "../Images/OrderonAmazon.png";

function GotWindbooster() {
  return (
    <section
          className="pt-12 sm:pt-0 xl:pt-10 h-[100vh] w-[100vw] text-center bg-[#142335] box-border snap-start"
          id="wind-booster"
        >
          <h1 className="text-white text-2xl  pt-[6.7rem]   md:text-[35px]  lg:text-[50px] xl:text-[90px] md:leading-[112px]   m-auto">
            What is <b>GOT Windbooster</b>?
          </h1>
          <div className="flex lg:pl-24 pl-5 gap-20 lg:gap-8 xl:gap-28 flex-col sm:flex-row items-center justify-center  ">
            <div className="flex flex-col gap-20 lg:gap-16  xl:gap-28 mt-4 sm:mt-0 xl:mt-10  items-center">
              <img
                data-aos="zoom-in-left"
                src={WindBooster1}
                className="md:w-[259px] color shadow-custom1 md:h-[165px]  md:pt-0 w-[160px]"
                alt=""
              />
              <a
                href="https://amazon.in"
                className="flex items-center justify-center lg:block md:pt-[10rem] sm:pt-0  lg:pt-0"
              >
                <img
                  src={OrderonAmazon}
                  className=" mt-2 md:w-[241px] md:h-[76px] w-32 "
                  alt=""
                />
              </a>
            </div>
            <h1 className="text-white lg:text-[30px] xl:text-[40px] md:leading-[48px] lg:ml-20 text-md sm:ml-11 md:ml-10  text-left w-[250px] sm:w-[500px] md:w-[640px] md:h-[294px]">
              <b>GOT Windbooster</b> is a remarkable <b>throttle controller</b>{" "}
              offering a diverse array of <b>9 driving modes</b>, including an{" "}
              <b>AI mode</b> infused with cutting-edge artificial intelligence
              technology.
              <h1 className="underline mt-4">
                <b>Know More...</b>
              </h1>
            </h1>
          </div>
        </section>  )
}

export default GotWindbooster